import React, { Component } from "react";
import { Container, Header, Form, Grid } from "semantic-ui-react";
import axios from "axios";

const Message = (props) => {
  return (
    <Grid celled>
      <Grid.Row>
        <Grid.Column width={6}>
          <p style={{ fontSize: 20 }}>
            <b>{props.message.username}</b>
          </p>
        </Grid.Column>
        <Grid.Column width={10}>
          <Header as="h3">{props.message.title}</Header>
          <p style={{ fontSize: 16 }}>{props.message.body}</p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default class SearchByUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      messages: [],
    };
  }

  handleSearch = (event) => {
    this.setState({ messages: [] });
    this.setState({ search: event.target.value });
    if (this.state.search.length >= 1) {
      axios
        .get(
          `https://socialappapi.satvikreddy.com/messages/search/${this.state.search}`
        )
        .then((res) => this.setState({ messages: res.data }))
        .catch((err) => console.log("regex failed"));
    }
    console.log(this.state.search + ", " + this.state.search.length);
  };

  createMessages = () => {
    return this.state.messages.map((currentMessage) => {
      return <Message message={currentMessage} />;
    });
  };

  render() {
    return (
      <Container>
        <Header as="h1">Search By User: </Header>
        <Form>
          <Form.Field>
            <label>User: </label>
            <input
              name="search"
              onChange={this.handleSearch}
              placeholder="ex. username123"
            ></input>
          </Form.Field>
          <div className="messages">{this.createMessages()}</div>
        </Form>
      </Container>
    );
  }
}
